import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`50% limitation relative automations`}</h1>
    <p>{`To work around the 50% limitation of relative automations, use bipolar mods, or use a math/multiply mod.`}</p>
    <h2>{`Need more?`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/tips-tricks"
        }}>{`more Bitwig Tips & Tricks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/"
        }}>{`BitWiki Overview`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      